.cart{
    position: relative;
    border: 1px solid #ccc;
    transform: scaleY(0.98);
}

.cart > img{
    width: 100%;
    height: 100%;
}
.amount span{
    color: crimson;
    padding: 0 20px;
}
.amount button{
    width: 40px;
    height: 40px;
    border: 1px solid #777;
}

.color {
    width: 30px;
    height: 30px;
    border: 1px solid #333;
    outline: none;
    margin-left: 10px;
    cursor: pointer;
    display: inline-block;

}
form{
    width: 100%;
    text-align: center;
}
.input_buy{
    width: 20%;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
    background-color:teal;;
    cursor: pointer;
    color: white;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 6px;
}
.delete{
    position: absolute;
    top:0;
    right: 5px;
    color: crimson;
    font-weight: 900;
    cursor: pointer;
}
.total{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.total h3{
    color: crimson;
}

.containerModalTwo{
    width: 70%;
    height: 40vh;
    background-color: white;
}

.containerChoice{
    /* border: 1px solid black; */
    border-left: none;
    border-right: none;
    width: 100%;
    display: inline-block;
    font-size: 22px;
    float: left;
    height: 5vh;
    font-weight: bold;
    text-align: center;
    border-color: #D3D3D3;
    @media (max-width: 1400px){
      font-size: 19px;
    }
    div{
      display: inline-block;
      margin-right: 5%;
      input{
        width: 20px;
        height:20px;
      }
    }
  };

  .login_page > #size{
    width: 100%;
    height: 40px;
    margin: 10px 0;
    padding: 0 5px;
    outline: rgb(3, 165, 206);
    border: 1px solid rgb(3, 165, 206);
  }

@media (max-width: 720px){
    .containerModalTwo{
        width: 95%;
        height: 20vh;
        background-color: white;
        border: 1px solid black;
    }
    .input_buy{
        width: 100%;
        height: 7vh;
    }
}