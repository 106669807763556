.products {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    justify-items: center;
    margin: 20px 0;
}

.heading_product {
    margin: 20px;
    margin-top: 5.1%;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    /* text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc,
                0 3px 0 #ccc, 0 4px 0 #ccc,
                0 5px 0 #ccc, 0 6px 0 #ccc,
                0 7px 0 #ccc, 0 8px 0 #ccc,
                0 9px 0 #ccc, 0 10px 0 #ccc,
                0 11px 0 #ccc, 0 12px 0 #ccc,
                0 20px 30px rgba(0, 0, 0, 0.5); */
}

/* ----------- Product Item ----------------- */
@import url("../utils/ProductItem/productItem.css");

.delete-all {
    text-align: right;
    margin: 20px;
}

.delete-all input {
    height: 25px;
    width: 25px;
    transform: translateY(5px);
    margin: 0 15px;
}

.delete-all span {
    text-transform: uppercase;
    color: rgb(3, 165, 206);
    letter-spacing: 1.3px;
}

.delete-all button {
    border: 1px solid crimson;
    padding: 10px 25px;
    color: crimson;
    text-transform: uppercase;
}

/* ------------ Filters Menu ----------------- */
.filter_menu {
    width: 100%;
    min-height: 40px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 15px 0;
    font-weight: bold;
    text-align: center;
}

.filter_menu select,
input {
    outline: none;
    height: 40px;
    padding: 0 5px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid black;
}

.filter_menu input {
    flex: 1;
    margin: 0 10px;
}

/* -------------------- Load More --------------- */
.load_more {
    text-align: center;
}

.load_more button {
    padding: 10px 25px;
    margin-bottom: 20px;
    border: 1px solid #555;
    text-transform: capitalize;
    background-color: #CA0000;
    font-weight: bold;
    color: white;
    border: none
}

@media (max-width: 500px) {

    .filter_menu{
        display: block;
    }

    .filter_menu select, input {
        margin-right: 0px;
        margin-bottom: 5px;
        border-radius: 5px;
        border: 1px solid black;
        width: 100%;
        height: 30px;
    }

    .heading_product {
        text-align: center;
        font-size: 25px;
    }

}