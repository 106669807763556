.contact{
  position: relative;
  min-height: 100vh;
  padding: 30px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-color: white;
}
.contact .content{
  max-width: 800px;
  text-align: center;
}
.contact .content h1{
  font-size: 36px;
  font-weight: 500;
  color: #555555;
  margin-bottom: 20px;
}
.contact .content p{
  font-weight: 300;
  color: black;
}
.container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.container .contactInfo{
  width: 50%;
  display: flex;
  flex-direction: column;
}
.container .contactInfo .box{
  position: relative;
  padding: 20px 0;
  display: flex;
}
.container .contactInfo .box .icon{
  min-width: 60px;
  height: 60px;
  background: gray;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 22px;
}
.container .contactInfo .box .text h3{
  display: flex;
  margin-left: 20px;
  font-size: 16px;
  color: #555555;
  /* flex-direction: column; */
  font-weight: 500;
}
.container .contactInfo .box .text p{
  display: flex;
  margin-left: 20px;
  font-size: 16px;
  color: rgb(8, 7, 7);
  /* flex-direction: column; */
  font-weight: 300;
}
.contactForm{
  width: 40%;
  padding: 40px;
  background: #fff;

}
.contactForm h2{
  font-size: 30px;
  color: #333;
  font-weight: 500;
}
.contactForm .inputBox{
  position: relative;
  width: 100%;
  margin-top: 10px;
}
.contactForm .inputBox input,
.contactForm .inputBox textarea{
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}
.contactForm .inputBox span{
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}
.contactForm .inputBox input:focus ~ span,
.contactForm .inputBox input:valid ~ span,
.contactForm .inputBox textarea:focus ~ span,
.contactForm .inputBox textarea:valid ~ span{
  color: #ff0000;
  font-size: 12px;
  transform: translateY(-20px);
}
.contactForm .inputBox input[type="submit"]{
  width: 100px;
  background: #00bcd4;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
}

@media screen and (max-width: 1541px){
  .iframe{
    max-width: 1300px;
  }
}

@media screen and (max-width: 1373px){
  .iframe{
    max-width: 1100px;
  }
  .contactForm h2{
    font-size: 20px;
  }

  .container .contactInfo{
    width: 39%;
  }

  .contactForm{
    width: 60%;
  }
}

@media screen and (max-width: 1175px){
  .iframe{
    max-width: 900px;
  }
  .contact{
    padding: 20px 40px;
  }
}
@media screen and (max-width: 977px){ 
  .iframe{
    max-width: 650px;
    max-height: 250px;
  }
  .container .contactInfo{
    width: 100%;
    display: block;
  }
  .container .contactInfo .box .icon{
    min-width: 40px;
    height: 40px;
    font-size: 15px;
  }
  .container .contactInfo .box .text p{
    margin-left: 20px;
    font-size: 14px;
    font-weight: 200;
  }
  .contactForm{
    width: 100%;
    display: block;
    padding: 15px;
  }
  .container{
    width: 100%;
    display: block;
  }
  .contact{
    padding: 5px 5px;
  }
}


@media screen and (max-width: 768px){
  .iframe{
    max-width: 300px;
    max-height: 130px;
  }

}

