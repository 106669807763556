.banner_card{
    max-width: 600px;
    overflow: hidden;
    height: 500px;
    padding: 15px;
    box-shadow: 0 0 15px #ddd;
    margin: 10px 10px;
    position: relative;
    display: inline-block;
}
.banner_card img{
    width: 100%;
    height: 300px;
    display: block;
    object-fit: cover;
   
}
.banner_box h2{
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-transform: capitalize;
    cursor: pointer;
    color: #323232;
}
.banner_card span{
    color: crimson;
    font-size: 18px;
}


.banner_box p{
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    height: 70px;
    overflow: hidden;
    color: #323232;
}
.row_btn{
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}
.row_btn a{
    width: 50%;
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 6px;
}
#btn_buy{
    background: #DC143C;
    margin-right: 5px;
}
#btn_view{
    background: teal;
    margin-left: 5px;
}
.banner_card input{
    position: absolute;
    width: 25px;
    height: 25px;
}