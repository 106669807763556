* {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App{
  max-width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0 20px;
  box-shadow: 0 0 35px #eee;
}
a{
  text-decoration: none;
}
ul li {
  list-style: none;
}
button{
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}

/* ---------------- Header ------------ */
@import url("./components/Headers/header.css");

/* ---------------- Footer ------------ */
@import url("./components/Footer/footer.css");

/* ------------- Products -------------- */

@import url("./components//MainPages/Products/products.css");

/* ------------- Banners -------------- */

@import url("./components//MainPages/Banners/banners.css");

/* ------------- Detail Products -------------- */

@import url("./components//MainPages/DetailProduct/detailProduct.css");

/* ------------- Login/Register -------------- */

@import url("./components//MainPages/Auth/login.css");

/* ------------- Cart -------------- */

@import url("./components//MainPages/Cart/cart.css");

/* ------------- Contact -------------- */

@import url("./components//MainPages/contact/contact.css");

/* ------------- Categories -------------- */

@import url("./components//MainPages/Categories/categories.css");

/* ------------- Create Product -------------- */

@import url("./components//MainPages/CreateProduct/createProduct.css");

/* ------------- Create Banner -------------- */

@import url("./components//MainPages/CreateBanner/createBanner.css");

/* ----------------- Responsive ------------------ */
/* ----------------- Responsive ------------------ */
@media (max-width: 820px){
  header .logo{
    flex: none;
    margin-left: 3%;
  }
  header ul{
   padding: 10px 0;
  }
}
@media (max-width: 720px){
  header ul{
   position: fixed;
   top:0;
   left: -100%;
   width: 100%;
   height: 100vh;
   background: white;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   align-items: center;
   opacity: 0.98;
   z-index: 99;
   transition: 0.5s ease-in;
  }
  header .menu{
    display: block;
    cursor: pointer;
  }
  header ul li .menu{
    position: absolute;
    top:20px;
    right: 20px;
  }
  header{
    justify-content: space-between;
  }
}

@media (max-width: 570px){
  .filter_menu .row span{
    display: none;
  }
}

@media (max-width: 500px){
  .App{
    padding: 0 10px;
  }
  .detail{
    font-size: 100%;
    padding: 0;
  }
  .filter_menu input{
    margin: 0;
  }
  .filter_menu .sort{
    flex: 1;
  }
  .filter_menu .sort select{
    width: 100%;
    min-width: 290px;
    margin: 5px 0;
  }
  .login-page{
    padding: 30px 15px;
  }
  .delete-all{
    margin: 20px 0;
  }
  .create_product .upload{
    margin: 20px 0;
  }
}