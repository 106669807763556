.product_card{
    max-width: 300px;
    min-width: 260px;
    overflow: hidden;
    height: 500px;
    padding: 15px;
    box-shadow: 0 0 15px #ddd;
    margin: 10px 0;
    position: relative;
}
.product_card img{
    width: 100%;
    height: 300px;
    display: block;
    object-fit: cover;
   
}
.product_box h2{
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-transform: capitalize;
    cursor: pointer;
    color: #323232;
}
.product_card span{
    color: crimson;
    font-size: 18px;
}

.product_card .sale {
    text-decoration: line-through; 
    float: right;
    color: black;
}

.product_card .sale_procent{
    position: absolute;
    top: 30px;
    right: 30px;
    border: 1px solid #CC2128;
    font-size: 15px;
    font-weight: bold;
    color: white;
    background-color: #CC2128;
    padding: 5px;
    border-radius: 10%;
}
.product_box p{
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    height: 70px;
    overflow: hidden;
    color: #323232;
}
.row_btn{
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}
.row_btn a{
    width: 50%;
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 6px;
}
#btn_buy{
    background: #555;
    margin-right: 5px;
}
#btn_view{
    background: teal;
    margin-left: 5px;
    width: 100%;
}
.product_card input{
    position: absolute;
    width: 25px;
    height: 25px;
}

@media (max-width: 500px){ 
    .product_card{
        max-width: 320px;
        min-width: 320px;
        height: 500px;
    }

    .product_card img{
        width: 100%;
        height: 400px;
        display: block;
        object-fit: cover;
       
    }
    .product_box p{
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        height: 35px;
        overflow: hidden;
        color: #323232;
    }
}