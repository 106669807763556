.div_new_offer{
    margin: 20px;
    text-align: center;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    /* text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc,
                0 3px 0 #ccc, 0 4px 0 #ccc,
                0 5px 0 #ccc, 0 6px 0 #ccc,
                0 7px 0 #ccc, 0 8px 0 #ccc,
                0 9px 0 #ccc, 0 10px 0 #ccc,
                0 11px 0 #ccc, 0 12px 0 #ccc,
                0 20px 30px rgba(0, 0, 0, 0.5); */

}

@media(max-width:720px){
    .div_new_offer{
        font-size: 12px;
    }
}